@import 'variables';
@import 'mixins';
@import 'grid';
@import '~bootstrap/scss/reboot';
// TODO add this in paragraph component
p {
  font-size: 16px;
  line-height: 1.3889em;
  color: #555555;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
form:focus {
  outline: none !important;
}
.transition-group-wrapper-up {
  position: relative;
  .step-animation {
    &-enter-done {
      position: relative;

      transition: transform 360ms ease, opacity 200ms ease;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    &-enter {
      transform: translate3d(0, 100px, 0);
      position: relative;

      opacity: 0;
    }
    &-exit {
      transition: transform 360ms ease, opacity 140ms ease;
      transform: translate3d(0, -100px, 0);
      opacity: 0;
    }
    &-exit-active {
      position: absolute;
      width: 100%;
      top: 0;
    }
  }
}

.transition-group-wrapper-left {
  position: relative;
  .step-animation {
    &-enter-done {
      position: relative;

      transition: transform 360ms ease, opacity 200ms ease;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    &-enter {
      transform: translate3d(100px, 0, 0);
      position: relative;

      opacity: 0;
    }
    &-exit {
      transition: transform 360ms ease, opacity 140ms ease;
      transform: translate3d(-100px, 0, 0);
      opacity: 0;
    }
    &-exit-active {
      position: absolute;
      width: 100%;
      top: 0;
    }
  }
}
.transition-group-wrapper-right {
  position: relative;
  .step-animation {
    &-enter-done {
      position: relative;

      transition: transform 360ms ease, opacity 200ms ease;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    &-enter {
      transform: translate3d(-100px, 0, 0);
      position: relative;

      opacity: 0;
    }
    &-exit {
      transition: transform 360ms ease, opacity 140ms ease;
      transform: translate3d(100px, 0, 0);
      opacity: 0;
    }
    &-exit-active {
      position: absolute;
      width: 100%;
      top: 0;
    }
  }
}
.transition-group-wrapper-down {
  position: relative;

  .step-animation {
    &-enter {
      transform: translate3d(0, -100px, 0);
      position: relative;

      opacity: 0;
    }

    &-enter-done {
      position: relative;

      transition: transform 360ms ease, opacity 160ms ease;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    &-exit {
      transition: transform 360ms ease, opacity 160ms ease;
      transform: translate3d(0, 100px, 0);
      opacity: 0;
    }

    &-exit-active {
      position: absolute;
      width: 100%;
      top: 0;
    }
  }
}


.flex-grow {
  flex-grow: 1;
}

.link {
  color: #4F9EA6;
  text-decoration: none;
}

.mt {
  margin-top: 2rem;
}

.cookie-consent-button-wrapper {
  margin: auto;
}