$grid-columns: 5;
$grid-breakpoints: (
  sm: 0
);
$grid-gutter-width: 16px;
$container-max-widths: (
  sm: 100vw
);

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// These mixins are overwriting internal bootstrap mixins
@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 (calc($size / $columns) * 100vw);
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: (calc($size / $columns) * 100vw);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: calc($size / $columns);
  // This & is needed to prevent csscomb from putting $num at the bottom of this block
  & {
    margin-left: if($num == 0, 0, ($num * 100vw));
  }
}

// create grid columns and wrappers with vars above
@import "~bootstrap/scss/grid";
// override vars and generate next breakpoint
$grid-columns: 7;
$grid-breakpoints: (
  md: $tabletMin
);
@include make-grid-columns();
// override vars and generate last breakpoint
$grid-columns: 15;
$grid-breakpoints: (
  lg: $desktopMin,
  xl: $desktopMax + 1
);
@include make-grid-columns();
@import "~bootstrap/scss/utilities/flex";
// fix for Chrome when using all 15 cols and scrollbar is present.
.row {
  &.chrome-vw {
    width: 100vw;
  }
}
// override bootstrap output because mobile has double-wide gutters at edge of page
@media (max-width: $mobileMax) {
  .container {
    padding-right: 16px;
    padding-left: 16px;
  }
  .col-sm-1 {
    flex: 0 0 calc(20vw - 3.2px);
    max-width: calc(20vw - 3.2px);
  }
  .col-sm-2 {
    flex: 0 0 calc(40vw - 6.4px);
    max-width: calc(40vw - 6.4px);
  }
  .col-sm-3 {
    flex: 0 0 calc(60vw - 9.6px);
    max-width: calc(60vw - 9.6px);
  }
  .col-sm-4 {
    flex: 0 0 calc(80vw - 12.8px);
    max-width: calc(80vw - 12.8px);
  }
  .col-sm-5 {
    flex: 0 0 calc(100vw - 16px);
    max-width: calc(100vw - 16px);
  }
  .offset-sm-1 {
    margin-left: calc(20vw - 3.2px);
  }
  .offset-sm-2 {
    margin-left: calc(40vw - 6.4px);
  }
  .offset-sm-3 {
    margin-left: calc(60vw - 9.6px);
  }
  .offset-sm-4 {
    margin-left: calc(80vw - 12.8px);
  }
}
